import { ModalControl, SecurityContext } from '@lib';
import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';

import {
  MutationCreateTradeArgs,
  MutationDeleteTradeArgs,
  MutationUpdateTradeArgs,
  Trade,
  UserRole,
} from '@redsleeve/oilynx-domain';

import CreateModal from '@components/TradeForm/CreateModal';
import DeleteModal from '@components/TradeForm/DeleteModal';
import UpdateModal from '@components/TradeForm/UpdateModal';
import { CategoryWithChildren } from '@components/category/domain';
import { SelectInputValue } from '@components/form/SelectInput';

import { useCategories } from '@lib/hooks/useOrderedCategories';

import './TradeForms.css';

type TradeFormsProps = {
  contactInfoValues?: SelectInputValue[];
  createTrade?: (args: {
    variables: MutationCreateTradeArgs;
  }) => Promise<unknown>;
  createModal?: ModalControl;
  editTrade: (args: { variables: MutationUpdateTradeArgs }) => Promise<unknown>;
  editModal: ModalControl<Trade>;
  deleteModal: ModalControl;
  deleteTrade: (args: {
    variables: MutationDeleteTradeArgs;
  }) => Promise<unknown>;
};

const TradeForms: FC<TradeFormsProps> = ({
  contactInfoValues = [],
  createTrade,
  createModal,
  editTrade,
  editModal,
  deleteTrade,
  deleteModal,
}) => {
  const { orderedCategories, groupedCategories } = useCategories();
  const { currentUser } = useContext(SecurityContext);
  const categoryOptions = useMemo(() => {
    function mapCategoryToSelectOption(
      category: CategoryWithChildren
    ): SelectInputValue {
      return {
        value: category.parentId ? category.id : undefined,
        label: category.name,
        options: category.children?.length
          ? category.children.map(mapCategoryToSelectOption)
          : undefined,
        // isDisabled:
        //   !currentUser ||
        //   (currentUser.roles.indexOf(UserRole.SuperAdmin) < 0 &&
        //     currentUser.interests.indexOf(category.parentId) < 0),
      };
    }

    return groupedCategories.map(mapCategoryToSelectOption);
  }, [groupedCategories, currentUser]);

  return (
    <div className="CreateTrade-wrapper">
      {createTrade && createModal && (
        <StaticQuery query={siteUrlQuery}>
          {(data) => (
            <CreateModal
              orderedCategories={orderedCategories}
              categoryValues={categoryOptions}
              contactInfoValues={contactInfoValues}
              createModal={createModal}
              createTrade={createTrade}
              siteUrl={data.site.siteMetadata.siteUrl}
            />
          )}
        </StaticQuery>
      )}

      <UpdateModal
        orderedCategories={orderedCategories}
        categoryValues={categoryOptions}
        editTrade={editTrade}
        editModal={editModal}
      />

      <DeleteModal
        orderedCategories={orderedCategories}
        deleteModal={deleteModal}
        deleteTrade={deleteTrade}
      />
    </div>
  );
};

const siteUrlQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default TradeForms;
