import { useQuery } from '@apollo/client';
import { FormikControl, useIsMobile, useModal } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, MutableRefObject, useEffect, useMemo } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';
import { NumberSchema } from 'yup';

import {
  GetPriceIndexesDocument,
  HistogramEntry,
  Quantity_Unit,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import RadioInput from '@components/form/RadioInput';
import RangeInput from '@components/form/RangeInput';
import SelectInput, { SelectInputValue } from '@components/form/SelectInput';
import TextInput from '@components/form/TextInput';

import { AVAILABLE_CURRENCIES } from '@data/currencyValues';

import DropDownFilter from './DropDownFilter';

const dollarPerMt = `${AVAILABLE_CURRENCIES[0].symbol}/${Quantity_Unit.Mt}`;

type PriceFilterProps = {
  formik: FormikControl;
  innerFormikRef?: MutableRefObject<FormikControl>;

  nameBenchmark: string;
  nameIndicationMin: string;
  nameIndicationMax: string;
  histogram: HistogramEntry[];
};

const PriceFilter: FC<PriceFilterProps> = ({
  formik,
  innerFormikRef = { current: undefined },
  nameBenchmark,
  nameIndicationMin,
  nameIndicationMax,
  histogram,
}) => {
  const dropDownControl = useModal();
  const isMobile = useIsMobile();

  const benchmarkValuesQuery = useQuery(GetPriceIndexesDocument);

  const benchmarkValues = useMemo(
    () =>
      benchmarkValuesQuery.data?.getPriceIndexes.map((it) => ({
        value: it.name,
        label: it.name,
      })) ?? [],
    [benchmarkValuesQuery.data]
  );

  const minIndication = useMemo(
    () => histogram.reduce((acc, it) => Math.min(acc, it.min), Infinity),
    [histogram]
  );
  const maxIndication = useMemo(
    () => histogram.reduce((acc, it) => Math.max(acc, it.max), -Infinity),
    [histogram]
  );

  const active =
    formik.values[nameBenchmark] !== '' ||
    formik.values[nameIndicationMin] !== '' ||
    formik.values[nameIndicationMax] !== '';

  const tempFormik = useFormik({
    initialValues: {
      filterType: (formik.values[nameBenchmark] !== '' || !active
        ? 'benchmark'
        : 'indication') as 'benchmark' | 'indication',
      benchmark: (formik.values[nameBenchmark] ||
        undefined) as SelectInputValue,
      minPrice: (formik.values[nameIndicationMin] || '') as number | '',
      maxPrice: (formik.values[nameIndicationMax] || '') as number | '',
    },
    validationSchema: Yup.object({
      minPrice: Yup.number()
        .min(0, 'Should be positive')
        .when('maxPrice', (max: number, schema: NumberSchema) => {
          return schema.max(max ?? Infinity, 'Should be lesser than Max');
        }),
      maxPrice: Yup.number().min(0, 'Should be positive'),
    }),
    onSubmit: (values) => {
      if (values.filterType === 'indication') {
        formik.setFieldValue(nameBenchmark, '');
        formik.setFieldValue(
          nameIndicationMin,
          values.minPrice !== minIndication ? values.minPrice : ''
        );
        formik.setFieldValue(
          nameIndicationMax,
          values.maxPrice !== maxIndication ? values.maxPrice : ''
        );
      } else {
        formik.setFieldValue(
          nameBenchmark,
          values.benchmark ? values.benchmark.value : ''
        );
        formik.setFieldValue(nameIndicationMin, '');
        formik.setFieldValue(nameIndicationMax, '');
      }
      dropDownControl.close();
    },
    onReset: () => {
      formik.setFieldValue(nameBenchmark, '');
      formik.setFieldValue(nameIndicationMin, '');
      formik.setFieldValue(nameIndicationMax, '');
      dropDownControl.close();
    },
  });
  useUpdateRefIfShallowNew(innerFormikRef, tempFormik);

  useEffect(() => {
    if (tempFormik.values.filterType === 'indication') {
      tempFormik.setFieldValue('benchmark', '');
      if (minIndication !== Infinity && tempFormik.values.minPrice === '') {
        tempFormik.setFieldValue('minPrice', minIndication);
      }
      if (maxIndication !== -Infinity && tempFormik.values.maxPrice === '') {
        tempFormik.setFieldValue('maxPrice', maxIndication);
      }
    } else {
      tempFormik.setFieldValue('minPrice', '');
      tempFormik.setFieldValue('maxPrice', '');
    }
  }, [tempFormik.values.filterType, minIndication, maxIndication]);

  return (
    <DropDownFilter
      label="Price"
      active={active}
      dropDownClassName="w-96"
      dropDownControl={dropDownControl}
    >
      <form
        onSubmit={tempFormik.handleSubmit}
        onReset={tempFormik.handleReset}
        className="mb-0"
      >
        <RadioInput
          className="text-sm"
          formik={tempFormik}
          name="filterType"
          label="Benchmark Pricing"
          value="benchmark"
        />
        {tempFormik.values.filterType === 'benchmark' && (
          <SelectInput
            formik={tempFormik}
            className="mt-4"
            name="benchmark"
            values={benchmarkValues}
            label="Benchmark"
            placeholder={`eg: CIF ARA`}
          />
        )}
        <hr className={'my-4 -mx-6 bg-interaction-border'} />

        <RadioInput
          className="text-sm mt-4"
          formik={tempFormik}
          name="filterType"
          label={`Price Indication (${dollarPerMt})`}
          value="indication"
        />
        {tempFormik.values.filterType === 'indication' && (
          <>
            {histogram.length && (
              <RangeInput
                className="mt-4"
                formik={tempFormik}
                startName="minPrice"
                endName="maxPrice"
                histogram={histogram}
              />
            )}
            <div className="flex flex-row space-x-14 mt-4">
              <TextInput
                type="number"
                name="minPrice"
                formik={tempFormik}
                label="Min"
                endPiece={dollarPerMt}
                autoFocus={!isMobile}
              />
              <TextInput
                type="number"
                name="maxPrice"
                formik={tempFormik}
                label="Max"
                endPiece={dollarPerMt}
              />
            </div>
          </>
        )}

        <div className="hidden lg:flex flex-row space-x-14 mt-9">
          <Button type="reset">Clear</Button>
          <Button type="submit" variant="primary">
            Apply
          </Button>
        </div>
      </form>
    </DropDownFilter>
  );
};

export default PriceFilter;
