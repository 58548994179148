export const incotermValues = [
  { value: 'EXW | Ex Works', label: 'EXW | Ex Works' },
  { value: 'FCA | Free Carrier', label: 'FCA | Free Carrier' },
  { value: 'CPT | Carriage Paid To', label: 'CPT | Carriage Paid To' },
  {
    value: 'CIP | Carriage and Insurance Paid To',
    label: 'CIP | Carriage and Insurance Paid To',
  },
  { value: 'DAP | Delivered at Place', label: 'DAP | Delivered at Place' },
  {
    value: 'DPU | Delivered at Place Unloaded',
    label: 'DPU | Delivered at Place Unloaded',
  },
  { value: 'DDP | Delivered Duty Paid', label: 'DDP | Delivered Duty Paid' },
  { value: 'FAS | Free Alongside Ship', label: 'FAS | Free Alongside Ship' },
  { value: 'FOB | Free On Board', label: 'FOB | Free On Board' },
  { value: 'CFR | Cost and Freight', label: 'CFR | Cost and Freight' },
  {
    value: 'CIF | Cost Insurance and Freight',
    label: 'CIF | Cost Insurance and Freight',
  },
];
