import * as React from 'react';
import { FC } from 'react';

import './NewsTicker.css';

type NewsTickerProps = {
  news: {
    headline: string;
    body: string;
  }[];
};

const NewsTicker: FC<NewsTickerProps> = ({ news }) => {
  // reactivate with lg:block
  return (
    <div className="NewsTicker-wrapper hidden">
      <div className="NewsTicker-ticker">
        {news.map((newsPiece) => (
          <div className="NewsTicker-item" key={JSON.stringify(newsPiece)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="NewsTicker-headline">{newsPiece.headline}:</span>
            <span className="NewsTicker-body">{newsPiece.body}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsTicker;
