import {
  getErrorMessages,
  getInvalidFields,
  mapInvalidFieldInvalidFieldNames,
  ModalControl,
} from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';
import { StringSchema } from 'yup';

import {
  Category,
  Differential_Type,
  MutationCreateTradeArgs,
  Trade_Type,
  TradeLocation,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import CommonFields, {
  createCommonValidationSchema,
} from '@components/TradeForm/CommonFields';
import ContactInfoFields from '@components/TradeForm/ContactInfoFields';
import { differentialValues } from '@components/TradeForm/values';
import SelectInput, { SelectInputValue } from '@components/form/SelectInput';
import Modal from '@components/modal/Modal';

import { countryValues, mapCountryToSelectValue } from '@data/countries';
import { currencyValues } from '@data/currencyValues';
import { incotermValues } from '@data/incotermValues';
import { quantityUnitValues } from '@data/quantityUnitValues';

import { useUserCountry } from '@lib/hooks/useUserCountry';

type CreateModalProps = {
  orderedCategories: Category[];

  contactInfoValues: SelectInputValue[];
  categoryValues: SelectInputValue[];
  createTrade: (args: {
    variables: MutationCreateTradeArgs;
  }) => Promise<unknown>;
  createModal: ModalControl;

  siteUrl: string;
};

const CreateModal: FC<CreateModalProps> = ({
  orderedCategories,
  contactInfoValues,
  categoryValues,
  createTrade,
  createModal,

  siteUrl,
}) => {
  const [pricingType, setPricingType] = useState<
    'priceIndication' | 'priceBenchmark'
  >('priceIndication');
  const userCountry = useUserCountry();

  const createFormik = useFormik({
    initialValues: {
      type: Trade_Type.Buy,
      category: undefined as { value: Category['id'] },
      density: '' as number | '',
      quantityUnit: quantityUnitValues[0],
      quantity: '' as number | '',
      quality: '',
      currency: currencyValues[0],

      priceIndication: '' as number | '',

      priceBenchmarkIndex: undefined as SelectInputValue,
      differentialType: differentialValues[0],
      differentialValue: '' as number | '',
      quotationPeriodStart: undefined as Date,
      quotationPeriodEnd: undefined as Date,

      deliveryWindowStart: undefined as Date,
      deliveryWindowEnd: undefined as Date,
      incoterm: undefined as (typeof incotermValues)[0],
      deliveryPlace: undefined as TradeLocation,
      additionalInfo: '',

      contactInfo: undefined as { value: string | object },
      contactInfoName: '',
      contactInfoCompanyName: '',
      contactInfoCompanyAddress: '',
      contactInfoCountry: undefined as (typeof countryValues)[0],
      contactInfoPhoneNumber: '',
      contactInfoEmail: '',
    },
    validationSchema: Yup.object({
      ...createCommonValidationSchema(pricingType),

      contactInfo: Yup.object().required('Please choose a contact method'),
      contactInfoName: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify a name for the contact person')
            : schema;
        }
      ),
      contactInfoCompanyName: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify the company name')
            : schema;
        }
      ),
      contactInfoCompanyAddress: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify the company address')
            : schema;
        }
      ),
      contactInfoPhoneNumber: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify a contact phone number')
            : schema;
        }
      ),
      contactInfoEmail: Yup.string().when(
        'contactInfo',
        (contactInfo: any, schema: StringSchema) => {
          return contactInfo?.value === 'OTHER'
            ? schema.required('Please specify a contact email')
            : schema;
        }
      ),
    }),

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Creating trade...');
      try {
        await createTrade({
          variables: {
            trade: {
              type: values.type,
              categoryId: values.category.value,
              density: values.density === '' ? null : values.density,
              quantityUnit: values.quantityUnit.value,
              quantity: values.quantity === '' ? null : values.quantity,
              quality: values.quality,
              currency:
                pricingType === 'priceIndication'
                  ? values.currency.value
                  : values.differentialType.value,

              priceIndication:
                pricingType === 'priceIndication'
                  ? values.priceIndication === ''
                    ? null
                    : values.priceIndication
                  : undefined,

              priceBenchmark:
                pricingType === 'priceBenchmark'
                  ? {
                      priceIndex: values.priceBenchmarkIndex.value,
                      differentialType:
                        values.differentialType.value === 'PERCENTAGE'
                          ? Differential_Type.Percentage
                          : Differential_Type.Fixed,
                      differential:
                        values.differentialValue === ''
                          ? null
                          : values.differentialType.value === 'PERCENTAGE'
                          ? values.differentialValue / 100
                          : values.differentialValue,
                      quotationPeriodStart:
                        values.quotationPeriodStart.getTime(),
                      quotationPeriodEnd: values.quotationPeriodEnd.getTime(),
                    }
                  : undefined,

              deliveryWindowStart: values.deliveryWindowStart.getTime(),
              deliveryWindowEnd: values.deliveryWindowEnd.getTime(),

              incoTerm: values.incoterm.value,
              deliveryPlace: values.deliveryPlace,
              additionalInfo: values.additionalInfo,

              customContactInfo:
                values.contactInfo?.value === 'OTHER'
                  ? {
                      name: values.contactInfoName,
                      companyName: values.contactInfoCompanyName,
                      companyAddress: values.contactInfoCompanyAddress,
                      country: values.contactInfoCountry.value.isoCode,
                      phoneNumber: values.contactInfoPhoneNumber,
                      email: values.contactInfoEmail,
                    }
                  : {
                      name: values.contactInfo?.value.name,
                      companyName: values.contactInfo?.value.companyName,
                      companyAddress: values.contactInfo?.value.companyAddress,
                      country: values.contactInfo?.value.country,
                      phoneNumber: values.contactInfo?.value.phoneNumber,
                      email: values.contactInfo?.value.email,
                    },
            },
            origin: siteUrl,
          },
        });

        toast.success('Trade created!', { id: toastId });
        createFormik.resetForm();
        createModal.close();
      } catch (ex) {
        const formError = mapInvalidFieldInvalidFieldNames(
          getInvalidFields(ex),
          new Map([
            ['customContactInfo.phoneNumber', 'contactInfoPhoneNumber'],
            ['customContactInfo.name', 'contactInfoName'],
            ['customContactInfo.email', 'contactInfoEmail'],
            ['customContactInfo.companyName', 'contactInfoCompanyName'],
            ['customContactInfo.companyAddress', 'contactInfoCompanyAddress'],
          ])
        );
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(errorMessages?.join('\n') ?? 'Failed to create trade', {
          id: toastId,
          duration: 10 * 1000,
        });
        if (!errorMessages)
          console.error('[HomePage] createTrade onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const createFormikRef = useRef(createFormik);
  useUpdateRefIfShallowNew(createFormikRef, createFormik);

  useEffect(() => {
    const newCategory = orderedCategories.find(
      (cat) => cat.id === createFormik.values.category?.value
    );
    if (newCategory?.density) {
      createFormikRef.current.setFieldValue('density', newCategory.density);
    }
  }, [orderedCategories, createFormikRef, createFormik.values.category]);

  useEffect(() => {
    if (createFormik.values.contactInfoCountry) {
      createFormikRef.current.setFieldValue(
        'contactInfoPhoneNumber',
        createFormik.values.contactInfoCountry.value.dialCode
      );
    }
  }, [createFormikRef, createFormik.values.contactInfoCountry]);

  useEffect(() => {
    if (!createFormik.values.contactInfoCountry && userCountry) {
      createFormik.setFieldValue(
        'contactInfoCountry',
        mapCountryToSelectValue(true, userCountry)
      );
    }
  }, [userCountry]);

  return (
    <>
      <Modal
        title="Create Trade"
        contentClassName="TradeForm-modal"
        control={createModal}
        formProps={{
          onSubmit: createFormik.handleSubmit,
        }}
        size="sm:max-w-3xl sm:w-auto"
        actions={
          <div className="p-4 lg:p-0 w-full bg-background-avg lg:bg-transparent flex justify-between">
            <Button
              className="w-1/3 lg:w-36"
              type="reset"
              onClick={createModal.close}
            >
              Cancel
            </Button>
            <Button
              className="w-2/3 lg:w-36"
              type="submit"
              name="submit"
              variant="primary"
            >
              Create Trade
            </Button>
          </div>
        }
      >
        <CommonFields
          formik={createFormik}
          pricingType={pricingType}
          setPricingType={setPricingType}
          categoryValues={categoryValues}
        />

        <hr className="mt-6 bg-white opacity-10" />
        <p className="text-xl mb-0 font-emp font-light">Contact Information</p>

        <div className="flex w-full">
          <SelectInput
            formik={createFormik}
            className="flex-1"
            name="contactInfo"
            values={contactInfoValues}
          />
        </div>

        {createFormik.values.contactInfo?.value === 'OTHER' && (
          <ContactInfoFields formik={createFormik} />
        )}
      </Modal>
    </>
  );
};

export default CreateModal;
