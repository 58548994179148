import { useMutation, useQuery } from '@apollo/client';
import { getQueryName, SecurityContext, useModal } from '@lib';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';

import {
  CreateTradeDocument,
  GetContactInfosDocument,
  GetTradeFilterDataDocument,
  GetTradesDocument,
  Trade,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';

import TradeForm from '../../components/TradeForm';
import './CreateTrade.css';

type CreateTradeProps = {};

const CreateTrade: FC<CreateTradeProps> = () => {
  const { currentUser, loginControl } = useContext(SecurityContext);
  const userWithContactInfos = useQuery(GetContactInfosDocument);
  const [createTrade] = useMutation(CreateTradeDocument, {
    refetchQueries: [
      getQueryName(GetTradesDocument),
      getQueryName(GetTradeFilterDataDocument),
    ],
  });
  const createModal = useModal();
  const editModal = useModal<Trade>();
  const deleteModal = useModal<Trade>();

  const contactInfoValues = useMemo(
    () => [
      ...(userWithContactInfos.data?.currentUser
        ? userWithContactInfos.data.currentUser.contactInfos.map(
            (contactInfo) => ({
              value: contactInfo,
              label: `${contactInfo.name}, ${contactInfo.email}, ${contactInfo.phoneNumber}, ${contactInfo.companyName}, ${contactInfo.companyAddress}`,
            })
          )
        : []),
      { value: 'OTHER', label: 'Other' },
    ],
    [userWithContactInfos]
  );

  function handleCreateTrade() {
    if (currentUser) createModal.open();
    else loginControl.open();
  }

  return (
    <>
      <div className="CreateTrade-wrapper">
        <Button
          onClick={handleCreateTrade}
          className={`items-center max-w-31
            lg:flex lg:font-bold  
          `}
          variant="primary"
        >
          <svg
            className="h-8 -m-3 lg:m-0 lg:-ml-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          <span className="text-white hidden lg:block">Add Trade</span>
        </Button>
      </div>

      <TradeForm
        contactInfoValues={contactInfoValues}
        deleteModal={deleteModal}
        editModal={editModal}
        createModal={createModal}
        createTrade={createTrade}
        editTrade={() => undefined}
        deleteTrade={() => undefined}
      />
    </>
  );
};

export default CreateTrade;
