import { isNullOrUndefined, ModalControl, useCategories } from '@lib';
import clsx from 'clsx';
import * as React from 'react';
import { FC, Fragment } from 'react';

import {
  Quantity_Unit,
  Trade_Type,
  TradeForTableFragment,
} from '@redsleeve/oilynx-domain';

import { AVAILABLE_CURRENCIES } from '@data/currencyValues';

import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPriceBenchmark,
} from '@lib/format';

type MobileTableProps = {
  trades: TradeForTableFragment[];
  viewModal: ModalControl<TradeForTableFragment>;
};

const MobileTable: FC<MobileTableProps> = ({ trades, viewModal }) => {
  const { getCategory } = useCategories();

  const rowClass = 'table-row whitespace-nowrap text-xs';
  const headerCellClass = 'table-cell opacity-40 pr-3 py-1 w-32';
  const valueCellClass = 'table-cell truncate py-1';

  return (
    <>
      {trades.map((trade, index, self) => (
        <Fragment key={trade.id}>
          {!trade.active && (!index || self[index - 1].active) && (
            <div className="flex space-x-4 w-full items-center opacity-60 mt-12 mb-4">
              <hr className="flex-1 bg-interaction-light w-full opacity-60 lg:opacity-100 h-1 mb-0" />
              <div
                className={'font-emp font-bold text-base'}
                style={{ letterSpacing: '4px' }}
              >
                COMPLETED TRADES
              </div>
              <hr className="flex-1 bg-interaction-light w-full opacity-60 lg:opacity-100 h-1 mb-0" />
            </div>
          )}
          <div className={clsx(!trade.active && 'opacity-70')}>
            <div className="flex items-center mb-2">
              <svg
                className={clsx(
                  'h-3 mr-3',
                  trade.type === Trade_Type.Buy
                    ? 'text-trade-buy'
                    : 'text-trade-sell'
                )}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="5 5 10 10"
                fill="currentColor"
              >
                {trade.type === Trade_Type.Buy ? (
                  <path
                    fillRule="evenodd"
                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                )}
              </svg>
              {getCategory(trade.categoryId)?.name ?? 'Other'}
              {trade.createdByMe && (
                <svg
                  className="h-4 ml-3 text-trade-mine"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>
            <div className="table table-fixed max-w-full w-full relative">
              <div className={rowClass}>
                <div className={headerCellClass}>Price /{Quantity_Unit.Mt}</div>
                <div className={valueCellClass}>
                  {trade.priceBenchmark
                    ? formatPriceBenchmark(trade)
                    : !isNullOrUndefined(trade.priceIndicationInUSDPerMt)
                    ? formatCurrency(
                        trade.priceIndicationInUSDPerMt,
                        AVAILABLE_CURRENCIES[0].id,
                        false
                      )
                    : `${formatCurrency(
                        trade.priceIndication,
                        trade.currency,
                        false
                      )} /${trade.quantityUnit}`}
                </div>
              </div>
              <div className={rowClass}>
                <div className={headerCellClass}>Delivery Window</div>
                <div className={valueCellClass}>
                  {formatDate(trade.deliveryWindowStart)}
                  {' - '}
                  {formatDate(trade.deliveryWindowEnd)}
                </div>
              </div>
              <div className={rowClass}>
                <div className={headerCellClass}>Quantity</div>
                <div className={valueCellClass}>
                  {!isNullOrUndefined(trade.quantityInMt)
                    ? `${Quantity_Unit.Mt} ${formatNumber(trade.quantityInMt)}`
                    : `${trade.quantityUnit} ${formatNumber(trade.quantity)}`}
                </div>
              </div>
              <div className={rowClass}>
                <div className={headerCellClass}>Quality</div>
                <div className={valueCellClass}>{trade.quality}</div>
              </div>
              <div className={rowClass}>
                <div className={headerCellClass}>Delivery Place</div>
                <div className={valueCellClass}>
                  {trade.deliveryPlace.label}
                </div>
              </div>

              <button
                className="absolute right-0 top-6 rounded-md bg-background-light"
                onClick={() => viewModal.open(trade)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 my-2 text-interaction-light"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <hr
              className={clsx(
                'my-3 opacity-10 h-0.5',
                index < self.length - 1 &&
                  self[index + 1].active === trade.active
                  ? 'bg-white'
                  : 'bg-transparent'
              )}
            />
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default MobileTable;
