import { FormikControl, TradesTableSort, useModal } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';

import { HistogramEntry } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import CategoryFilter from '@components/filter/CategoryFilter';
import DeliveryWindowFilter from '@components/filter/DeliveryWindowFilter';
import IncotermFilter from '@components/filter/IncotermFilter';
import PriceFilter from '@components/filter/PriceFilter';
import QuantityFilter from '@components/filter/QuantityFilter';
import RadioInput from '@components/form/RadioInput';
import Modal from '@components/modal/Modal';

type MobileFilterProps = {
  mode: 'map' | 'table';
  filterFormik: FormikControl;
  quantityHistogram: HistogramEntry[];
  priceHistogram: HistogramEntry[];

  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
};

const MobileFilter: FC<MobileFilterProps> = ({
  mode,
  filterFormik,
  quantityHistogram,
  priceHistogram,
  sort,
  setSort,
}) => {
  const sortModalControl = useModal();
  const filterModalControl = useModal();

  const deliveryWindowFormikRef = useRef<FormikControl>();
  const priceFormikRef = useRef<FormikControl>();
  const quantityFormikRef = useRef<FormikControl>();

  const numberActive = [
    filterFormik.values['categories'].length,

    filterFormik.values['priceBenchmark'] !== '' ||
      filterFormik.values['minPriceIndicationInDollarsPerMT'] !== '' ||
      filterFormik.values['maxPriceIndicationInDollarsPerMT'] !== '',

    filterFormik.values['minQuantityInMT'] !== '' ||
      filterFormik.values['maxQuantityInMT'] !== '',

    !!filterFormik.values['deliveryWindowStart'] ||
      !!filterFormik.values['deliveryWindowEnd'],
  ].filter((it) => !!it).length;

  async function onClear() {
    filterFormik.setFieldValue('categories', []);
    await deliveryWindowFormikRef.current?.resetForm();
    await priceFormikRef.current?.resetForm();
    await quantityFormikRef.current?.resetForm();

    filterModalControl.close();
  }

  async function onApply() {
    await deliveryWindowFormikRef.current?.submitForm();
    await priceFormikRef.current?.submitForm();
    await quantityFormikRef.current?.submitForm();

    filterModalControl.close();
  }

  const sortFormik = useFormik({
    initialValues: {
      sort: '-_id',
    },
    onSubmit: (values) => {
      setSort(values.sort);
      sortModalControl.close();
    },
    onReset: () => {
      setSort(sortFormik.initialValues.sort);
      sortModalControl.close();
    },
  });

  useEffect(() => {
    if (!sortModalControl.isOpen) {
      sortFormik.setFieldValue('sort', sort);
    }
  }, [sortModalControl.isOpen]);

  function renderButtons() {
    if (mode === 'table') {
      return (
        <div className="flex lg:hidden space-x-3 mt-5 mb-5">
          <Button
            variant="outlined-light"
            className="flex-1 p-1 flex items-center"
            onClick={() => filterModalControl.open()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 mr-2 text-interaction-light"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
            </svg>
            Filter
          </Button>
          <Button
            variant="outlined-light"
            className="flex-1 p-1 flex items-center"
            onClick={() => sortModalControl.open()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 mr-2 text-interaction-light"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
            </svg>
            Sort
          </Button>
        </div>
      );
    } else {
      return (
        <Button
          variant="outlined-light"
          className="lg:hidden w-8 h-8 fixed right-0 top-10 z-10 transform -translate-x-3 translate-y-3"
          onClick={() => filterModalControl.open()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 -m-2 -mt-1.5 text-interaction-light"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
          </svg>
          {!!numberActive && (
            <div
              className={`absolute bottom-0 left-0 
              transform -translate-x-1/2 translate-y-1/2 
              rounded-full bg-interaction-light text-white text-xs
              w-4 h-4 text-center
            `}
            >
              {numberActive}
            </div>
          )}
        </Button>
      );
    }
  }

  return (
    <>
      {renderButtons()}

      <Modal
        title="Sort"
        contentClassName="h-screen"
        childrenWrapperClassName="flex flex-col"
        control={sortModalControl}
        formProps={{
          onSubmit: sortFormik.handleSubmit,
          onReset: sortFormik.handleReset,
        }}
      >
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Product A-Z"
          value={TradesTableSort.ProductsASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Product Z-A"
          value={TradesTableSort.ProductsDESC}
        />

        <RadioInput
          className="text-sm mt-4"
          formik={sortFormik}
          name="sort"
          label="Price ascending"
          value={TradesTableSort.PriceASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Price descending"
          value={TradesTableSort.PriceDESC}
        />

        <RadioInput
          className="text-sm  mt-4"
          formik={sortFormik}
          name="sort"
          label="Quantity ascending"
          value={TradesTableSort.QuantityASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Quantity descending"
          value={TradesTableSort.QuantityDESC}
        />

        <RadioInput
          className="text-sm mt-4"
          formik={sortFormik}
          name="sort"
          label="Delivery Window ascending"
          value={TradesTableSort.DeliveryWindowASC}
        />
        <RadioInput
          className="text-sm"
          formik={sortFormik}
          name="sort"
          label="Delivery Window descending"
          value={TradesTableSort.DeliveryWindowDESC}
        />

        <div className="flex-1" />

        <div className="bg-background-avg flex flex-row space-x-6 -m-4 p-4">
          <Button className="p-2 w-1/3" type="reset">
            Reset
          </Button>
          <Button className="p-2 w-2/3" type="submit" variant="primary">
            Apply
          </Button>
        </div>
      </Modal>

      <Modal title="Filters" control={filterModalControl}>
        <CategoryFilter formik={filterFormik} name="categories" />
        <hr className="my-4 bg-white opacity-10 h-0.5" />

        <IncotermFilter formik={filterFormik} name="incoterms" />
        <hr className="my-4 bg-white opacity-10 h-0.5" />

        <PriceFilter
          formik={filterFormik}
          innerFormikRef={priceFormikRef}
          nameBenchmark="priceBenchmark"
          nameIndicationMin="minPriceIndicationInDollarsPerMT"
          nameIndicationMax="maxPriceIndicationInDollarsPerMT"
          histogram={priceHistogram}
        />
        <hr className="my-4 bg-white opacity-10 h-0.5" />

        <QuantityFilter
          formik={filterFormik}
          innerFormikRef={quantityFormikRef}
          nameLeft="minQuantityInMT"
          nameRight="maxQuantityInMT"
          histogram={quantityHistogram}
        />
        <hr className="my-4 bg-white opacity-10 h-0.5" />

        <DeliveryWindowFilter
          formik={filterFormik}
          innerFormikRef={deliveryWindowFormikRef}
          nameStart="deliveryWindowStart"
          nameEnd="deliveryWindowEnd"
        />

        <div className="bg-background-avg flex flex-row space-x-6 -m-4 p-4 mt-0">
          <Button className="p-2 w-1/3" type="reset" onClick={onClear}>
            Clear All
          </Button>
          <Button
            className="p-2 w-2/3"
            type="submit"
            variant="primary"
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MobileFilter;
