import { useMutation } from '@apollo/client';
import {
  getErrorMessages,
  getQueryName,
  isNullOrUndefined,
  ModalControl,
  SecurityContext,
} from '@lib';
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import {
  CompletedTradeDocument,
  GetTradesDocument,
  MutationDeleteTradeArgs,
  MutationUpdateTradeArgs,
  Trade_Type,
  TradeForManagementTableFragment,
} from '@redsleeve/oilynx-domain';
import {
  Trade,
  UserStatus,
} from '@redsleeve/oilynx-domain/build/generated/typed-document-nodes';

import CategoryDotsMenu from '@components/CategoryDotsMenu';
import TradeForm from '@components/TradeForm';
import Modal from '@components/modal/Modal';

import { COUNTRY_MAP, COUNTRY_UNDEFINED } from '@data/countries';
import { AVAILABLE_CURRENCIES } from '@data/currencyValues';

import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPriceBenchmarkDifferential,
} from '@lib/format';
import { useCategories } from '@lib/hooks/useOrderedCategories';
import {
  DEFAULT_MAP_LOCATION,
  mapboxGl,
  MapboxGlMap,
  MapboxGlMarker,
} from '@lib/mapbox';

import './TradeInfo.css';

type TradeInfoProps = {
  tradeModal: ModalControl<Trade>;
  editTrade: (args: { variables: MutationUpdateTradeArgs }) => Promise<unknown>;
  editModal: ModalControl<Trade>;
  deleteModal: ModalControl;
  deleteTrade: (args: {
    variables: MutationDeleteTradeArgs;
  }) => Promise<unknown>;
};

const TradeInfo: FC<TradeInfoProps> = ({
  tradeModal,
  editModal,
  editTrade,
  deleteTrade,
  deleteModal,
}) => {
  const mapContainer = useRef();
  const mapRef = useRef<MapboxGlMap>();
  const markerRef = useRef<MapboxGlMarker>();
  const [category, setCategoryName] = useState('');
  const { orderedCategories: categories } = useCategories();
  const { currentUser, isSuperAdmin } = useContext(SecurityContext);
  const [isTradeCompleted, setTsTradeCompleted] = useState(
    tradeModal.data.completed
  );
  const [completeTrade] = useMutation(CompletedTradeDocument, {
    refetchQueries: [getQueryName(GetTradesDocument)],
  });

  const completeTradeHandler = async () => {
    const toastId = toast.loading('Creating trade...');
    try {
      await completeTrade({
        variables: {
          tradeId: tradeModal.data.id,
          status: !isTradeCompleted,
        },
      });

      toast.success(
        `Trade marked as ${isTradeCompleted ? 'incomplete' : 'complete'}!`,
        { id: toastId }
      );
      setTsTradeCompleted(!isTradeCompleted);
    } catch (ex) {
      const errorMessages = getErrorMessages(ex);
      toast.error(
        errorMessages?.join('\n') ??
          `Failed to mark trade as ${
            isTradeCompleted ? 'incomplete' : 'complete'
          }.`,
        {
          id: toastId,
          duration: 10 * 1000,
        }
      );
      if (!errorMessages) console.error('[TradeInfo] completeTradeHandler', ex);
    }
  };

  useEffect(() => {
    mapRef.current = mapContainer.current
      ? new mapboxGl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/dark-v10',
          center: [
            tradeModal.data.deliveryPlace.location.coordinates[0],
            tradeModal.data.deliveryPlace.location.coordinates[1],
          ],
          zoom: DEFAULT_MAP_LOCATION.zoom,
        })
      : null;
    return () => mapRef.current?.remove();
  }, [mapRef]);

  useEffect(() => {
    if (!mapRef.current) return;
    markerRef.current = new mapboxGl.Marker()
      .setLngLat([
        tradeModal.data.deliveryPlace?.location.coordinates[0],
        tradeModal.data.deliveryPlace?.location.coordinates[1],
      ])
      .addTo(mapRef.current);
  }, [mapRef.current]);

  useEffect(() => {
    const category = categories.find(
      (category) => category.id === tradeModal.data.categoryId
    );
    category && setCategoryName(category.name);
  }, [tradeModal.data, categories]);

  const contactInfoCountry =
    COUNTRY_MAP.get(tradeModal.data.contactInfo?.country) ?? COUNTRY_UNDEFINED;

  const handleDeleteTrade: typeof deleteTrade = async (args) => {
    await deleteTrade(args);
    tradeModal.close();
  };

  const rowClassName = 'table-row lg:block';
  const headerCellClassName = `
    table-cell lg:block w-1/3 lg:w-auto 
    text-sm font-emp lg:font-body opacity-40 lg:opacity-60 
    mb-0 py-2 pr-4 lg:p-0
  `;
  const valueCellClassName = `
    table-cell lg:block w-2/3 lg:w-auto 
    font-emp text-sm lg:text-base
  `;

  return (
    <>
      <div className="TradeInfo-wrapper">
        <Modal control={tradeModal} contentClassName="TradeInfo-modal" size="">
          <div className="flex items-center justify-center lg:justify-start pb-10 lg:-mt-2">
            <span className="h-7 w-7 bg-background-light rounded-full flex justify-center items-center">
              <svg
                className={clsx(
                  'h-3 w-3',
                  tradeModal.data.type === Trade_Type.Buy
                    ? 'text-trade-buy'
                    : 'text-trade-sell'
                )}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="5 5 10 10"
                fill="currentColor"
              >
                {tradeModal.data.type === Trade_Type.Buy ? (
                  <path
                    fillRule="evenodd"
                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                )}
              </svg>
            </span>
            <p className="pl-4 mb-0 font-emp font-bold text-xl lg:text-4xl">
              {tradeModal.data.type === Trade_Type.Buy ? 'Buying' : 'Selling'}
              {' - '}
              <span className="pl-0.5">{category}</span>
            </p>
            {tradeModal.data.createdByMe && (
              <svg
                className="h-4 ml-3 text-trade-mine"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            <span className="absolute top-4 right-4 lg:right-12">
              {(tradeModal.data.createdByMe || isSuperAdmin) && (
                <CategoryDotsMenu
                  deleteButton={'Delete'}
                  editButton={'Edit'}
                  deleteHandler={() =>
                    deleteModal.open(
                      tradeModal.data as TradeForManagementTableFragment
                    )
                  }
                  editHandler={() =>
                    editModal.open(
                      tradeModal.data as TradeForManagementTableFragment
                    )
                  }
                  completeHandler={completeTradeHandler}
                  markAsCompleted={true}
                  isCompleted={
                    !isTradeCompleted
                      ? 'Mark as complete'
                      : 'Mark as incomplete'
                  }
                  showOwnerEdit={false}
                />
              )}
            </span>
          </div>

          <div className="flex flex-wrap w-full">
            {!currentUser ? (
              <div className="m-auto h-64 flex flex-col items-center justify-center   ">
                <div className="mb-8 font-emp font-bold text-base lg:text-2xl">
                  Please Login to view Trade details
                </div>
                <Link
                  to="/login/"
                  className=" flex justify-center items-center no-underline	w-20 h-12 bg-background-light hover:bg-background-avg rounded "
                >
                  Login
                </Link>
              </div>
            ) : (
              <>
                <div className="w-full lg:w-3/5">
                  <div>
                    <p className="font-emp font-bold text-base lg:text-2xl mb-2 lg:mb-5 pb-1">
                      General Information
                    </p>

                    <div className="table table-fixed">
                      <div className={rowClassName}>
                        <p className={clsx(headerCellClassName)}>Quantity</p>
                        <p className={clsx(valueCellClassName)}>
                          {tradeModal.data.quantityUnit}{' '}
                          {formatNumber(tradeModal.data.quantity)}
                        </p>
                      </div>

                      <div className={rowClassName}>
                        <p className={clsx(headerCellClassName)}>Quality</p>
                        <p className={clsx(valueCellClassName)}>
                          {tradeModal.data.quality}
                        </p>
                      </div>

                      <div className={rowClassName}>
                        <p className={clsx(headerCellClassName)}>
                          Additional Information
                        </p>
                        <p className={clsx(valueCellClassName)}>
                          {tradeModal.data.additionalInfo || 'N/A'}
                        </p>
                      </div>
                    </div>
                    <hr className="bg-interaction-light w-full opacity-10 lg:opacity-100 mb-3.5" />
                  </div>

                  <div>
                    <p className="font-emp font-bold text-base lg:text-2xl mb-2 lg:mb-5 pb-1">
                      Pricing
                    </p>

                    <div className="table table-fixed lg:flex flex-wrap">
                      {tradeModal.data.priceIndication !== null ? (
                        <>
                          <div className={clsx(rowClassName, 'lg:w-2/4')}>
                            <p className={headerCellClassName}>
                              Price Indication
                            </p>
                            <p className={valueCellClassName}>
                              {!isNullOrUndefined(
                                tradeModal.data.priceIndicationInUSDPerMt
                              )
                                ? `${formatCurrency(
                                    tradeModal.data.priceIndicationInUSDPerMt,
                                    AVAILABLE_CURRENCIES[0].id
                                  )} /${tradeModal.data.quantityUnit}`
                                : `${formatCurrency(
                                    tradeModal.data.priceIndication,
                                    tradeModal.data.currency
                                  )} /${tradeModal.data.quantityUnit}`}
                            </p>
                          </div>
                          {tradeModal.data.currency !==
                            AVAILABLE_CURRENCIES[0].id && (
                            <div className={clsx(rowClassName, 'lg:w-2/4')}>
                              <p className={headerCellClassName}>
                                Original Currency
                              </p>
                              <p className={valueCellClassName}>
                                {tradeModal.data.currency}
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div className={clsx(rowClassName, 'lg:w-2/4')}>
                            <p className={headerCellClassName}>Benchmark</p>
                            <p className={valueCellClassName}>
                              {tradeModal.data.priceBenchmark.priceIndex}
                            </p>
                          </div>
                          <div className={clsx(rowClassName, 'lg:w-2/4')}>
                            <p className={headerCellClassName}>Differential</p>
                            <p className={valueCellClassName}>
                              {formatPriceBenchmarkDifferential(
                                tradeModal.data,
                                true
                              )}{' '}
                              /{tradeModal.data.quantityUnit}
                            </p>
                          </div>
                          <div className={clsx(rowClassName, 'lg:w-2/4')}>
                            <div className={clsx(headerCellClassName)}>
                              <div className="flex items-center">
                                Quotation Period
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="hidden lg:block h-5 w-5 ml-2 pb-0 text-trade-mine"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className={valueCellClassName}>
                              <div className="flex items-center lg:mb-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="hidden lg:block h-4 w-4 text-trade-mine"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <p className="lg:ml-2 mb-0">
                                  {formatDate(
                                    tradeModal.data.priceBenchmark
                                      .quotationPeriodStart
                                  )}
                                  {' - '}
                                  {formatDate(
                                    tradeModal.data.priceBenchmark
                                      .quotationPeriodEnd
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <hr className="bg-interaction-light w-full opacity-10 lg:opacity-100 mb-3.5" />
                  </div>

                  <div>
                    <p className="font-emp font-bold text-base lg:text-2xl mb-2 lg:mb-5 pb-1">
                      Delivery Information
                    </p>
                    <div className="table table-fixed lg:flex flex-wrap">
                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={headerCellClassName}>Delivery Window</p>
                        <div className={valueCellClassName}>
                          <div className="flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="hidden lg:block h-4 w-4 text-trade-mine"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="lg:ml-2 mb-0">
                              {formatDate(tradeModal.data.deliveryWindowStart)}
                              {' - '}
                              {formatDate(tradeModal.data.deliveryWindowEnd)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={headerCellClassName}>Incoterms</p>
                        <p className={valueCellClassName}>
                          {tradeModal.data.incoTerm}
                        </p>
                      </div>
                      <div className={clsx(rowClassName, 'lg:w-2/4')}>
                        <p className={headerCellClassName}>Delivery Place</p>
                        <p className={valueCellClassName}>
                          {tradeModal.data.deliveryPlace.label}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lg:pl-10 flex flex-col-reverse lg:flex-col w-full lg:w-2/5 -mt-7">
                  <div
                    className={`ContactInformation-wrapper
                bg-background-light 
                -mx-4 mt-4 -mb-4 lg:m-0 p-4 lg:px-8 lg:pt-7 lg:pb-0 
                lg:rounded
              `}
                  >
                    <p className="font-emp font-bold text-base lg:text-2xl mb-2 lg:mb-5 pb-1">
                      Contact Information
                    </p>

                    {!currentUser ? (
                      <div className="h-64 flex items-center justify-center">
                        <Link
                          to="/login/"
                          className=" flex justify-center items-center no-underline	w-20 h-12 bg-background-dark hover:bg-background-avg rounded "
                        >
                          Login
                        </Link>
                      </div>
                    ) : currentUser.status === UserStatus.Disabled ? (
                      <div className="h-64">Your account is disabled</div>
                    ) : currentUser.status ===
                      UserStatus.AwaitingConfirmation ? (
                      <div className="h-64">
                        Your account is awaiting for confirmation
                      </div>
                    ) : !!tradeModal.data.contactInfo ? (
                      <div className="table table-fixed">
                        <div className={rowClassName}>
                          <p className={clsx(headerCellClassName)}>
                            {tradeModal.data.type === 'BUY'
                              ? 'Buyer'
                              : 'Seller'}
                          </p>
                          <p className={clsx(valueCellClassName)}>
                            {tradeModal.data.contactInfo.name}
                          </p>
                        </div>
                        <div className={rowClassName}>
                          <p className={clsx(headerCellClassName)}>
                            Phone Number
                          </p>
                          <div className={clsx(valueCellClassName)}>
                            <div className="flex items-center">
                              <img
                                className="w-5 h-5 mb-0"
                                src={contactInfoCountry.flag}
                                alt={`${contactInfoCountry.name} flag`}
                              />
                              <span className="pl-2">
                                {tradeModal.data.contactInfo.phoneNumber}
                              </span>
                              <span className="pl-5">W</span>
                              <span className="">T</span>
                            </div>
                          </div>
                        </div>
                        <div className={rowClassName}>
                          <p className={clsx(headerCellClassName)}>Email</p>
                          <p className={clsx(valueCellClassName)}>
                            {tradeModal.data.contactInfo.email}
                          </p>
                        </div>
                        <div className={rowClassName}>
                          <p className={clsx(headerCellClassName)}>
                            Company Details
                          </p>
                          <p className={clsx(valueCellClassName)}>
                            {tradeModal.data.contactInfo.companyName}
                            {', '}
                            {tradeModal.data.contactInfo.companyAddress}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="h-64">
                        You are not subscribed to this category
                      </div>
                    )}
                  </div>
                  <div className="pt-10">
                    <div
                      ref={mapContainer}
                      className={`block h-36 lg:h-72 w-full rounded`}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
      <div className="CreateTrade-wrapper">
        <TradeForm
          editModal={editModal}
          editTrade={editTrade}
          deleteModal={deleteModal}
          deleteTrade={handleDeleteTrade}
        />
      </div>
    </>
  );
};
export default TradeInfo;
