import { useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import { getQueryName, SecurityContext, useIsMobile, useModal } from '@lib';
import clsx from 'clsx';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';

import {
  DeleteTradeDocument,
  GetTradeFilterDataDocument,
  GetTradesDocument,
  Trade,
  TradeForManagementTableFragment,
  UpdateTradeDocument,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import OilMap from '@components/Map';
import TradeInfo from '@components/TradeInfo/TradeInfo';
import TradesTable, { TableHeader } from '@components/TradesTable';
import CategoryFilter from '@components/filter/CategoryFilter';
import ClearFilters from '@components/filter/ClearFilters';
import DeliveryWindowFilter from '@components/filter/DeliveryWindowFilter';
import { FiltersContextProvider } from '@components/filter/FiltersContext';
import IncotermFilter from '@components/filter/IncotermFilter';
import MobileFilter from '@components/filter/MobileFilter';
import PriceFilter from '@components/filter/PriceFilter';
import QuantityFilter from '@components/filter/QuantityFilter';
import NewsTicker from '@components/ticker/NewsTicker';

import CategoryDotsMenu from '../../components/CategoryDotsMenu';
import TradeForm from '../../components/TradeForm';
import { TradesAndShippingsContext } from '../TradesAndShippingsContext';
import CreateTrade from './CreateTrade';
import './CreateTrade.css';
import './index.css';

type HomePageProps = PageProps & {};
const HomePage: FC<HomePageProps> = ({ location, navigate }) => {
  const {
    tradeSort: sort,
    setTradeSort: setSort,
    tradesFilterFormik: filterFormik,
    getTradesQuery,
  } = useContext(TradesAndShippingsContext);

  const [mobileMode, setMobileMode] = useState<'map' | 'table'>('map');
  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile && mobileMode == 'map') {
      window.scrollTo({ top: 0 });
    }
  }, [isMobile, mobileMode]);

  const { currentUser } = useContext(SecurityContext);

  const tradeFilterDataQuery = useQuery(GetTradeFilterDataDocument);

  const viewTradeModal = useModal<Trade>();

  // open modal for each highlight once
  useEffect(() => {
    const selectedTradeOnRender = (getTradesQuery.data?.getTrades ?? []).find(
      (it) => location.hash === `#${it.id}`
    );
    if (selectedTradeOnRender) {
      viewTradeModal.open(selectedTradeOnRender as any);
      navigate('/', { replace: true });
    }
  }, [getTradesQuery.data?.getTrades, location.hash]);

  const editModal = useModal<Trade>();
  const deleteModal = useModal<Trade>();
  const [editTrade] = useMutation(UpdateTradeDocument, {
    refetchQueries: [
      getQueryName(GetTradesDocument),
      getQueryName(GetTradeFilterDataDocument),
    ],
  });
  const [deleteTrade] = useMutation(DeleteTradeDocument, {
    refetchQueries: [
      getQueryName(GetTradesDocument),
      getQueryName(GetTradeFilterDataDocument),
    ],
  });

  return (
    <Layout
      url="/"
      title="Home"
      aboveMainContent={
        <NewsTicker
          news={[
            { headline: 'Woohoo', body: 'We implemented some design! 🎉' },
            {
              headline: 'Missing provider',
              body: "We don't have a news provider, so I'm making these up.",
            },
            {
              headline: 'People stories',
              body: 'F was able to sleep last night.',
            },
            {
              headline: 'People stories',
              body: 'Is S getting a dog?',
            },
          ]}
        />
      }
      hideFooter={isMobile && mobileMode === 'map'}
    >
      <FiltersContextProvider>
        <h1
          className={clsx(
            `font-emp font-bold text-2xl text-center w-full mb-0
          mt-3 lg:mt-6
          `,
            mobileMode === 'map' && 'hidden lg:block'
          )}
        >
          Trades
        </h1>
        <p className="hidden lg:block w-full text-center font-emp text-xs opacity-70">
          Oilynx is your platform to place, monitor, and discover new trading
          opportunities in your industry. Start right here!
        </p>

        <CreateTrade />

        <OilMap mobileMode={mobileMode}>
          <CategoryFilter formik={filterFormik} name="categories" />
          <IncotermFilter formik={filterFormik} name="incoterms" />
          <QuantityFilter
            formik={filterFormik}
            nameLeft="minQuantityInMT"
            nameRight="maxQuantityInMT"
            histogram={
              tradeFilterDataQuery.data?.getTradeFilterData
                ?.quantityHistogram ?? []
            }
          />
          <PriceFilter
            formik={filterFormik}
            nameBenchmark="priceBenchmark"
            nameIndicationMin="minPriceIndicationInDollarsPerMT"
            nameIndicationMax="maxPriceIndicationInDollarsPerMT"
            histogram={
              tradeFilterDataQuery.data?.getTradeFilterData?.priceHistogram ??
              []
            }
          />
          <DeliveryWindowFilter
            formik={filterFormik}
            nameStart="deliveryWindowStart"
            nameEnd="deliveryWindowEnd"
          />
          <ClearFilters />
        </OilMap>

        <MobileFilter
          mode={mobileMode}
          filterFormik={filterFormik}
          quantityHistogram={
            tradeFilterDataQuery.data?.getTradeFilterData?.quantityHistogram ??
            []
          }
          priceHistogram={
            tradeFilterDataQuery.data?.getTradeFilterData?.priceHistogram ?? []
          }
          sort={sort}
          setSort={setSort}
        />

        <div className={clsx(mobileMode === 'map' && `hidden lg:block`)}>
          <TradesTable
            trades={getTradesQuery.data?.getTrades ?? []}
            sort={sort}
            setSort={setSort}
            viewModal={viewTradeModal}
            highlightRow={location.hash}
            lastColumnHeader={
              currentUser && (
                <TableHeader
                  className="flex-row-reverse has-tooltip"
                  label={
                    <svg
                      className="h-4 text-trade-mine"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                >
                  <span className="tooltip text-white transform translate-x-full -mr-4">
                    My trades
                  </span>
                </TableHeader>
              )
            }
            renderLastColumnCell={(trade) =>
              currentUser && (
                <td
                  className="flex flex-row-reverse"
                  onClick={(e) => trade.createdByMe && e.stopPropagation()}
                >
                  {trade.createdByMe && (
                    <CategoryDotsMenu
                      deleteButton={'Delete'}
                      editButton={'Edit'}
                      showOwnerEdit={true}
                      deleteHandler={() =>
                        deleteModal.open(
                          trade as TradeForManagementTableFragment
                        )
                      }
                      editHandler={() =>
                        editModal.open(trade as TradeForManagementTableFragment)
                      }
                    />
                  )}
                </td>
              )
            }
          />
        </div>
        <Button
          className="lg:hidden fixed bottom-10 w-52 p-2 rounded-full left-1/2 transform -translate-x-1/2"
          variant="outlined-light"
          onClick={() =>
            setMobileMode((mode) => (mode === 'map' ? 'table' : 'map'))
          }
        >
          {mobileMode === 'map' ? 'Table' : 'Map'}
        </Button>
        <div className="CreateTrade-wrapper">
          <TradeForm
            editModal={editModal}
            deleteModal={deleteModal}
            editTrade={editTrade}
            deleteTrade={deleteTrade}
          />
        </div>
        {viewTradeModal.data && (
          <TradeInfo
            editTrade={editTrade}
            editModal={editModal}
            deleteTrade={deleteTrade}
            deleteModal={deleteModal}
            tradeModal={viewTradeModal}
          />
        )}
      </FiltersContextProvider>
    </Layout>
  );
};

export default HomePage;
