import {
  isNullOrUndefined,
  ModalControl,
  TradesTableSort,
  useIsMobile,
} from '@lib';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode } from 'react';

import {
  Quantity_Unit,
  Trade_Type,
  TradeForTableFragment,
} from '@redsleeve/oilynx-domain';

import MobileTable from '@components/TradesTable/MobileTable';

import { AVAILABLE_CURRENCIES } from '@data/currencyValues';

import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPriceBenchmark,
} from '@lib/format';
import { useCategories } from '@lib/hooks/useOrderedCategories';

import './TradesTable.css';

type TableHeaderProps = {
  label: ReactNode;
  className?: string;
  sort?: string;
  sortASC?: string;
  sortDESC?: string;
  setSort?: React.Dispatch<React.SetStateAction<string>>;
};

export const TableHeader: FC<TableHeaderProps> = ({
  className,
  label,
  sort,
  setSort,
  sortASC,
  sortDESC,
  children,
}) => (
  <th className={'text-sm font-emp text-gray-500 border-b  border-gray-700'}>
    <div className={clsx('flex items-center', className)}>
      <div
        className={clsx('text-left', setSort && 'cursor-pointer')}
        onClick={() =>
          setSort && setSort((sort) => (sort === sortASC ? sortDESC : sortASC))
        }
      >
        {label}
      </div>
      {setSort && (
        <div
          className="flex flex-col justify-center text-xs leading-3 pl-2"
          style={{ fontSize: '11px' }}
        >
          <button
            className={clsx(sort === sortASC && 'text-trade-mine')}
            onClick={() => setSort(sortASC)}
          >
            ▲
          </button>
          <button
            className={clsx('-mt-0.5', sort === sortDESC && 'text-trade-mine')}
            onClick={() => setSort(sortDESC)}
          >
            ▼
          </button>
        </div>
      )}
      {children}
    </div>
  </th>
);

type TradesTableProps = {
  trades: TradeForTableFragment[];

  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;

  lastColumnHeader: ReactNode;
  renderLastColumnCell: (trade: TradeForTableFragment) => ReactNode;

  highlightRow?: string;
  viewModal: ModalControl<TradeForTableFragment>;
};

const TradesTable: FC<TradesTableProps> = ({
  trades,
  sort,
  setSort,
  lastColumnHeader,
  renderLastColumnCell,
  highlightRow,
  viewModal,
}) => {
  const isMobile = useIsMobile();
  const { getCategory } = useCategories();

  if (!trades.length)
    return (
      <div className="TradesTable-wrapper">
        <p>No trades match the filters.</p>
      </div>
    );

  return (
    <div className="TradesTable-wrapper">
      {isMobile ? (
        <MobileTable trades={trades} viewModal={viewModal} />
      ) : (
        <table>
          <thead>
            <tr>
              <TableHeader
                label="Products"
                setSort={setSort}
                sort={sort}
                sortASC={TradesTableSort.ProductsASC}
                sortDESC={TradesTableSort.ProductsDESC}
              />
              <TableHeader label="Quality" />
              <TableHeader
                label={`Price /${Quantity_Unit.Mt}`}
                setSort={setSort}
                sort={sort}
                sortASC={TradesTableSort.PriceASC}
                sortDESC={TradesTableSort.PriceDESC}
              />
              <TableHeader
                label="Quantity"
                setSort={setSort}
                sort={sort}
                sortASC={TradesTableSort.QuantityASC}
                sortDESC={TradesTableSort.QuantityDESC}
              />
              <TableHeader
                label="Delivery Window"
                setSort={setSort}
                sort={sort}
                sortASC={TradesTableSort.DeliveryWindowASC}
                sortDESC={TradesTableSort.DeliveryWindowDESC}
              />
              <TableHeader
                label="Delivery Place"
                // setSort={setSort}
                // sort={sort}
                // sortASC={TradesTableSort.DeliveryPlaceASC}
                // sortDESC={TradesTableSort.DeliveryPlaceDESC}
              />
              {lastColumnHeader}
            </tr>
          </thead>
          <tbody>
            {trades.map((trade, index) => (
              <React.Fragment key={trade.id}>
                {!trade.active && (!index || trades[index - 1].active) && (
                  <tr>
                    <td colSpan={10} className="divider">
                      <div className="flex space-x-16 w-full items-center opacity-60 mt-12">
                        <hr className="flex-1 bg-interaction-light w-full opacity-10 lg:opacity-100 h-0.5 mb-0" />
                        <div
                          className={'font-emp font-bold text-base'}
                          style={{ letterSpacing: '4px' }}
                        >
                          COMPLETED TRADES
                        </div>
                        <hr className="flex-1 bg-interaction-light w-full opacity-10 lg:opacity-100 h-0.5 mb-0" />
                      </div>
                    </td>
                  </tr>
                )}
                <tr
                  onClick={() => viewModal.open(trade as any)}
                  id={trade.id}
                  className={clsx(
                    'hover:bg-background-avg cursor-pointer',
                    highlightRow === `#${trade.id}` && 'bg-background-avg',
                    !trade.active && 'opacity-70'
                  )}
                >
                  <td className="flex items-center has-tooltip">
                    <svg
                      className={clsx(
                        'h-3 mr-3',
                        trade.type === Trade_Type.Buy
                          ? 'text-trade-buy'
                          : 'text-trade-sell'
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="5 5 10 10"
                      fill="currentColor"
                    >
                      {trade.type === Trade_Type.Buy ? (
                        <path
                          fillRule="evenodd"
                          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        />
                      ) : (
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      )}
                    </svg>
                    {getCategory(trade.categoryId)?.name ?? 'Other'}
                    <span className="tooltip transform -translate-x-full -ml-4">
                      {trade.type === Trade_Type.Buy ? 'Buy' : 'Sell'}
                    </span>
                  </td>
                  <td>{trade.quality}</td>
                  <td>
                    {trade.priceBenchmark
                      ? formatPriceBenchmark(trade)
                      : !isNullOrUndefined(trade.priceIndicationInUSDPerMt)
                      ? formatCurrency(
                          trade.priceIndicationInUSDPerMt,
                          AVAILABLE_CURRENCIES[0].id,
                          false
                        )
                      : `${formatCurrency(
                          trade.priceIndication,
                          trade.currency,
                          false
                        )} /${trade.quantityUnit}`}
                  </td>
                  <td>
                    {!isNullOrUndefined(trade.quantityInMt)
                      ? `${Quantity_Unit.Mt} ${formatNumber(
                          trade.quantityInMt
                        )}`
                      : `${trade.quantityUnit} ${formatNumber(trade.quantity)}`}
                  </td>
                  <td>
                    {formatDate(trade.deliveryWindowStart)}
                    {' - '}
                    {formatDate(trade.deliveryWindowEnd)}
                  </td>
                  <td>{trade.deliveryPlace.label}</td>
                  {renderLastColumnCell(trade)}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TradesTable;
