import { useIsMobile, useModal } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import CheckboxInput from '@components/form/CheckboxInput';

import { incotermValues } from '@data/incotermValues';

import DropDownFilter from './DropDownFilter';

type IncotermFilterProps = {
  formik: ReturnType<typeof useFormik>;
  name: string;
};

const IncotermFilter: FC<IncotermFilterProps> = ({ formik, name }) => {
  const dropDownControl = useModal();
  const isMobile = useIsMobile();
  const activeFilters = formik.values[name].length;

  return (
    <DropDownFilter
      label={`Incoterm${
        !isMobile && activeFilters ? ` (${activeFilters})` : ''
      }`}
      active={!!activeFilters}
      dropDownClassName="w-60"
      dropDownControl={dropDownControl}
    >
      <div className="-mt-4">
        {incotermValues.map((incoterm) => (
          <div key={incoterm.value}>
            <CheckboxInput
              className="text-sm"
              formik={formik}
              name={name}
              label={incoterm.label}
              value={incoterm.value}
            />
          </div>
        ))}
      </div>
    </DropDownFilter>
  );
};

export default IncotermFilter;
