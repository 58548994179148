import { useQuery } from '@apollo/client';
import { FormikControl } from '@lib';
import * as React from 'react';
import { FC, SetStateAction, useMemo } from 'react';
import { AnySchema, NumberSchema, StringSchema } from 'yup';
import * as Yup from 'yup';

import { GetPriceIndexesDocument } from '@redsleeve/oilynx-domain';

import {
  differentialValues,
  tradeTypeValues,
} from '@components/TradeForm/values';
import ButtonSwitchInput from '@components/form/ButtonSwitchInput';
import DateIntervalInput, {
  DateStartPiece,
} from '@components/form/DateIntervalInput';
import GeocoderInput, { PinStartPiece } from '@components/form/GeocoderInput';
import MapInput from '@components/form/MapInput';
import SelectAndTextInput from '@components/form/SelectAndTextInput';
import SelectInput, { SelectInputValue } from '@components/form/SelectInput';
import TextInput from '@components/form/TextInput';

import { currencyValues } from '@data/currencyValues';
import { incotermValues } from '@data/incotermValues';
import { quantityUnitValues } from '@data/quantityUnitValues';

type CommonFieldsProps = {
  formik: FormikControl;
  pricingType: 'priceIndication' | 'priceBenchmark';
  setPricingType: React.Dispatch<
    SetStateAction<'priceIndication' | 'priceBenchmark'>
  >;
  categoryValues: SelectInputValue[];
};

const CommonFields: FC<CommonFieldsProps> = ({
  formik,
  pricingType,
  setPricingType,
  categoryValues,
}) => {
  const benchmarkValuesQuery = useQuery(GetPriceIndexesDocument);

  const benchmarkValues = useMemo(
    () =>
      benchmarkValuesQuery.data?.getPriceIndexes.map((it) => ({
        value: it.name,
        label: it.name,
      })) ?? [],
    [benchmarkValuesQuery.data]
  );

  return (
    <>
      <p className="text-xl font-emp font-light">General information</p>
      <ButtonSwitchInput
        formik={formik}
        className="w-full lg:w-72"
        name="type"
        values={tradeTypeValues}
      />
      <div className="mt-4 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
        <SelectInput
          formik={formik}
          className="w-full lg:w-72"
          name="category"
          values={categoryValues}
          label="Product *"
          placeholder="eg: Butane"
        />
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="density"
          label="Density"
          tooltip="The density is used to accurately convert the quantity to MT, if needed. Only add/change this if necessary."
          type="number"
          placeholder="eg: 994"
          endPiece={
            <span>
              kg/m<sup>3</sup>
            </span>
          }
        />
      </div>
      <div className="mt-4 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6">
        <SelectAndTextInput
          formik={formik}
          className="w-full lg:w-72"
          label="Quantity *"
          select={{
            name: 'quantityUnit',
            values: quantityUnitValues,
          }}
          text={{
            name: 'quantity',
            type: 'number',
            placeholder: 'eg: 42',
          }}
        />
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="quality"
          label="Quality *"
          placeholder="eg: Meets Turkish Specs"
        />
      </div>

      <hr className="mt-6 bg-white opacity-10" />
      <p className="text-xl font-emp font-light">Pricing</p>

      <div className="flex w-full lg:w-72 items-center">
        <input
          className={`w-6 h-6 mr-5 mt-7`}
          type="radio"
          name="pricing"
          value="priceIndication"
          checked={pricingType === 'priceIndication'}
          onChange={(event) =>
            event.target.checked && setPricingType('priceIndication')
          }
        />
        <SelectAndTextInput
          formik={formik}
          className=""
          label={`Price Indication${
            pricingType === 'priceIndication' ? ' *' : ''
          }`}
          select={{
            name: 'currency',
            values: currencyValues,
            disabled: pricingType !== 'priceIndication',
          }}
          text={{
            name: 'priceIndication',
            type: 'number',
            endPiece: `/${formik.values.quantityUnit.label}`,
            disabled: pricingType !== 'priceIndication',
          }}
        />
      </div>
      <div className="flex mt-6 items-center flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
        <div className="flex w-full lg:w-72">
          <input
            className="w-6 h-6 mr-5 mt-7"
            type="radio"
            name="pricing"
            value="priceBenchmark"
            checked={pricingType === 'priceBenchmark'}
            onChange={(event) =>
              event.target.checked && setPricingType('priceBenchmark')
            }
          />
          <SelectInput
            formik={formik}
            className="flex-1"
            name="priceBenchmarkIndex"
            values={benchmarkValues}
            label={`Benchmark${pricingType === 'priceBenchmark' ? ' *' : ''}`}
            disabled={pricingType !== 'priceBenchmark'}
            showErrors={pricingType === 'priceBenchmark'}
            placeholder={`eg: CIF ARA`}
          />
        </div>
        <SelectAndTextInput
          formik={formik}
          className="w-full lg:w-72 pl-11 lg:pl-0"
          label={`Differential${pricingType === 'priceBenchmark' ? ' *' : ''}`}
          showErrors={pricingType === 'priceBenchmark'}
          select={{
            name: 'differentialType',
            values: differentialValues,
            disabled: pricingType !== 'priceBenchmark',
          }}
          text={{
            name: 'differentialValue',
            type: 'number',
            placeholder: 'eg: +10%',
            disabled: pricingType !== 'priceBenchmark',
            endPiece:
              formik.values.differentialType.value === 'PERCENTAGE'
                ? `%`
                : `${
                    formik.values.differentialType.buttonLabel ??
                    formik.values.differentialType.label
                  }/${formik.values.quantityUnit.label}`,
          }}
        />
      </div>
      <div className="pl-11 mt-6 w-full lg:w-72">
        <DateIntervalInput
          formik={formik}
          startName="quotationPeriodStart"
          endName="quotationPeriodEnd"
          label={`Quotation Period${
            pricingType === 'priceBenchmark' ? ' *' : ''
          }`}
          placeholder="How long is this price valid?"
          disabled={pricingType !== 'priceBenchmark'}
          showError={pricingType === 'priceBenchmark'}
          startPiece={<DateStartPiece />}
        />
      </div>

      <hr className="mt-6 bg-white opacity-10" />
      <p className="text-xl font-emp font-light">Delivery Information</p>

      <div className="mt-6 w-full lg:w-72">
        <DateIntervalInput
          formik={formik}
          startName="deliveryWindowStart"
          endName="deliveryWindowEnd"
          label="Delivery Window *"
          startPiece={<DateStartPiece />}
        />
      </div>
      <div className="flex mt-6 items-center flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
        <div className="flex w-full lg:w-72">
          <SelectInput
            formik={formik}
            className="flex-1"
            name="incoterm"
            values={incotermValues}
            label="Incoterm *"
            placeholder={`eg: ${incotermValues[0].label}`}
          />
        </div>
        <div className="w-full lg:w-72">
          <GeocoderInput
            formik={formik}
            name="deliveryPlace"
            label="Delivery Place *"
            placeholder="eg: New York"
            startPiece={<PinStartPiece />}
          />
        </div>
      </div>
      <div className="flex mt-6 w-full h-52">
        <MapInput formik={formik} name="deliveryPlace" />
      </div>
      <div className="flex mt-6 w-full">
        <TextInput
          formik={formik}
          className="w-full"
          name="additionalInfo"
          label="Additional Info"
          placeholder="eg: I only trade in euro"
          type="textarea"
          rows={4}
        />
      </div>
    </>
  );
};

export default CommonFields;

export const createCommonValidationSchema = (
  pricingType: 'priceIndication' | 'priceBenchmark'
) => ({
  category: Yup.object().required('Please specify the trade product'),
  density: Yup.number().min(0, 'Density should be a positive number'),
  quantity: Yup.number()
    .min(0, 'Quantity should be a positive number')
    .required('Please specify the quantity'),
  quality: Yup.string().required('Please describe the product quality'),

  priceIndication: Yup.number()
    .min(0, 'Should be positive')
    .when('type', (type: string, schema: NumberSchema) => {
      return pricingType === 'priceIndication'
        ? schema.required('Please specify a price indication')
        : schema;
    }),
  priceBenchmarkIndex: Yup.object().when(
    'type',
    (type: string, schema: AnySchema) => {
      return pricingType === 'priceBenchmark'
        ? schema.required('Please specify an index')
        : schema;
    }
  ),
  differentialValue: Yup.number().when(
    'type',
    (type: string, schema: NumberSchema) => {
      return pricingType === 'priceBenchmark'
        ? schema.required('Please specify a differential')
        : schema;
    }
  ),
  quotationPeriodStart: Yup.string().when(
    'type',
    (type: string, schema: StringSchema) => {
      return pricingType === 'priceBenchmark'
        ? schema.required('Please specify a quotation period')
        : schema;
    }
  ),
  quotationPeriodEnd: Yup.string()
    .nullable()
    .when(
      'quotationPeriodStart',
      (quotationPeriodStart: any, schema: StringSchema) => {
        return !!quotationPeriodStart && pricingType === 'priceBenchmark'
          ? schema.required('Please specify a quotation period')
          : schema;
      }
    ),

  deliveryWindowStart: Yup.string().required(
    'Please specify the delivery window'
  ),
  deliveryWindowEnd: Yup.string()
    .nullable()
    .when(
      'deliveryWindowStart',
      (deliveryWindowStart: any, schema: StringSchema) => {
        return !!deliveryWindowStart
          ? schema.required('Please specify the delivery window')
          : schema;
      }
    ),

  incoterm: Yup.object().required('Please specify an incoterm'),
  deliveryPlace: Yup.object().required('Please specify the delivery place'),
});
