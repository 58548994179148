import { Trade_Type } from '@redsleeve/oilynx-domain';

import { SelectInputValue } from '@components/form/SelectInput';

import { currencyValues } from '@data/currencyValues';

export const tradeTypeValues = [
  { value: Trade_Type.Buy, label: 'Buy' },
  { value: Trade_Type.Sell, label: 'Sell' },
];

export const differentialValues: SelectInputValue[] = [
  { value: 'PERCENTAGE', label: '%' },
  ...currencyValues,
];
