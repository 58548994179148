import { useIsMobile, useModal } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import CheckboxInput from '@components/form/CheckboxInput';

import { useCategories } from '@lib/hooks/useOrderedCategories';

import DropDownFilter from './DropDownFilter';

type CategoryFilterProps = {
  formik: ReturnType<typeof useFormik>;
  name: string;
};

const CategoryFilter: FC<CategoryFilterProps> = ({ formik, name }) => {
  const { groupedCategories } = useCategories();
  const dropDownControl = useModal();
  const isMobile = useIsMobile();
  const activeFilters = formik.values[name].length;

  return (
    <DropDownFilter
      label={`Products${
        !isMobile && activeFilters ? ` (${activeFilters})` : ''
      }`}
      active={!!activeFilters}
      dropDownClassName="w-60"
      dropDownControl={dropDownControl}
    >
      <div className="-mt-4">
        {groupedCategories.map((cat) => (
          <div key={cat.id}>
            <p className="opacity-60 mb-0 mt-4 text-sm">{cat.name}</p>
            {cat.children.map((subCat) => (
              <CheckboxInput
                key={subCat.id}
                className="text-sm"
                formik={formik}
                name={name}
                label={subCat.name}
                value={subCat.id}
              />
            ))}
          </div>
        ))}
      </div>
    </DropDownFilter>
  );
};

export default CategoryFilter;
