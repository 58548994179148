import { useQuery } from '@apollo/client';
import {
  getErrorMessages,
  getInvalidFields,
  mapInvalidFieldInvalidFieldNames,
  ModalControl,
} from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';
import * as Yup from 'yup';

import {
  Category,
  Differential_Type,
  GetPriceIndexesDocument,
  MutationUpdateTradeArgs,
  Trade,
  Trade_Type,
  TradeLocation,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import CommonFields, {
  createCommonValidationSchema,
} from '@components/TradeForm/CommonFields';
import ContactInfoFields from '@components/TradeForm/ContactInfoFields';
import { differentialValues } from '@components/TradeForm/values';
import { SelectInputValue } from '@components/form/SelectInput';
import Modal from '@components/modal/Modal';

import {
  COUNTRY_MAP,
  COUNTRY_UNDEFINED,
  countryValues,
  mapCountryToSelectValue,
} from '@data/countries';
import { currencyValues } from '@data/currencyValues';
import { incotermValues } from '@data/incotermValues';
import { quantityUnitValues } from '@data/quantityUnitValues';

type UpdateModalProps = {
  orderedCategories: Category[];
  categoryValues: SelectInputValue[];

  editTrade: (args: { variables: MutationUpdateTradeArgs }) => Promise<unknown>;
  editModal: ModalControl<Trade>;
};

const UpdateModal: FC<UpdateModalProps> = ({
  editModal,
  editTrade,
  orderedCategories,
  categoryValues,
}) => {
  const [pricingType, setPricingType] = useState<
    'priceIndication' | 'priceBenchmark'
  >('priceIndication');

  const benchmarkValuesQuery = useQuery(GetPriceIndexesDocument);

  const benchmarkValues = useMemo(
    () =>
      benchmarkValuesQuery.data?.getPriceIndexes.map((it) => ({
        value: it.name,
        label: it.name,
      })) ?? [],
    [benchmarkValuesQuery.data]
  );

  const updateFormikInitialValues = {
    type: Trade_Type.Buy,
    category: undefined as { value: Category['id'] },
    density: '' as number | '',
    quantityUnit: quantityUnitValues[0],
    quantity: '' as number | '',
    quality: '',
    currency: currencyValues[0],

    priceIndication: '' as number | '',

    priceBenchmarkIndex: undefined as (typeof benchmarkValues)[0],
    differentialType: differentialValues[0],
    differentialValue: '' as number | '',
    quotationPeriodStart: undefined as Date,
    quotationPeriodEnd: undefined as Date,

    deliveryWindowStart: undefined as Date,
    deliveryWindowEnd: undefined as Date,
    incoterm: undefined as (typeof incotermValues)[0],
    deliveryPlace: undefined as TradeLocation,
    additionalInfo: '',

    contactInfoName: '',
    contactInfoCompanyName: '',
    contactInfoCompanyAddress: '',
    contactInfoCountry: undefined as (typeof countryValues)[0],
    contactInfoPhoneNumber: '',
    contactInfoEmail: '',
  };
  const updateFormik = useFormik({
    initialValues: updateFormikInitialValues,
    validationSchema: Yup.object({
      ...createCommonValidationSchema(pricingType),

      contactInfoName: Yup.string().required(
        'Please specify a name for the contact person'
      ),
      contactInfoCompanyName: Yup.string().required(
        'Please specify the company name'
      ),
      contactInfoCompanyAddress: Yup.string().required(
        'Please specify the company address'
      ),
      contactInfoPhoneNumber: Yup.string().required(
        'Please specify a contact phone number'
      ),
      contactInfoEmail: Yup.string().required('Please specify a contact email'),
    }),

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Updating trade...');

      try {
        await editTrade({
          variables: {
            tradeId: editModal.data.id,
            trade: {
              type: values.type,
              categoryId: values.category.value,
              density: values.density === '' ? null : values.density,
              quantityUnit: values.quantityUnit.value,
              quantity: values.quantity === '' ? null : values.quantity,
              quality: values.quality,

              currency:
                pricingType === 'priceIndication'
                  ? values.currency.value
                  : values.differentialType.value,

              priceIndication:
                pricingType === 'priceIndication'
                  ? values.priceIndication === ''
                    ? null
                    : values.priceIndication
                  : undefined,

              priceBenchmark:
                pricingType === 'priceBenchmark'
                  ? {
                      priceIndex: values.priceBenchmarkIndex.value,
                      differentialType:
                        values.differentialType.value === 'PERCENTAGE'
                          ? Differential_Type.Percentage
                          : Differential_Type.Fixed,
                      differential:
                        values.differentialValue === ''
                          ? null
                          : values.differentialType.value === 'PERCENTAGE'
                          ? values.differentialValue / 100
                          : values.differentialValue,
                      quotationPeriodStart:
                        values.quotationPeriodStart.getTime(),
                      quotationPeriodEnd: values.quotationPeriodEnd.getTime(),
                    }
                  : undefined,

              deliveryWindowStart: values.deliveryWindowStart.getTime(),
              deliveryWindowEnd: values.deliveryWindowEnd.getTime(),

              incoTerm: values.incoterm.value,
              deliveryPlace: {
                label: values.deliveryPlace.label,
                location: {
                  type: values.deliveryPlace.location.type,
                  coordinates: values.deliveryPlace.location.coordinates,
                },
              },
              additionalInfo: values.additionalInfo,

              customContactInfo: {
                name: values.contactInfoName,
                companyName: values.contactInfoCompanyName,
                companyAddress: values.contactInfoCompanyAddress,
                country: values.contactInfoCountry.value.isoCode,
                phoneNumber: values.contactInfoPhoneNumber,
                email: values.contactInfoEmail,
              },
            },
          },
        });

        toast.success('Trade updated!', { id: toastId });
        updateFormik.resetForm();
        editModal.close();
      } catch (ex) {
        const formError = mapInvalidFieldInvalidFieldNames(
          getInvalidFields(ex),
          new Map([
            ['customContactInfo.phoneNumber', 'contactInfoPhoneNumber'],
            ['customContactInfo.name', 'contactInfoName'],
            ['customContactInfo.email', 'contactInfoEmail'],
            ['customContactInfo.companyName', 'contactInfoCompanyName'],
            ['customContactInfo.companyAddress', 'contactInfoCompanyAddress'],
          ])
        );
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(errorMessages?.join('\n') ?? 'Failed to update trade', {
          id: toastId,
          duration: 10 * 1000,
        });
        if (!errorMessages)
          console.error('[HomePage] updateTrade onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const updateFormikRef = useRef(updateFormik);
  useUpdateRefIfShallowNew(updateFormikRef, updateFormik);

  useEffect(() => {
    const newCategory = orderedCategories.find(
      (cat) => cat.id === updateFormik.values.category?.value
    );
    if (newCategory?.density) {
      updateFormikRef.current.setFieldValue('density', newCategory.density);
    }
  }, [orderedCategories, updateFormikRef, updateFormik.values.category]);

  useEffect(() => {
    if (
      updateFormik.values.contactInfoCountry &&
      updateFormikRef.current.values.contactInfoPhoneNumber &&
      updateFormikRef.current.values.contactInfoPhoneNumber.indexOf(
        updateFormik.values.contactInfoCountry.value.dialCode
      ) !== 0
    ) {
      updateFormikRef.current.setFieldValue(
        'contactInfoPhoneNumber',
        updateFormik.values.contactInfoCountry.value.dialCode
      );
    }
  }, [updateFormikRef, updateFormik.values.contactInfoCountry]);

  useEffect(() => {
    if (editModal.data) {
      const trade = editModal.data;
      const newPricingType = trade.priceBenchmark
        ? 'priceBenchmark'
        : 'priceIndication';
      setPricingType(newPricingType);
      updateFormikRef.current.resetForm({
        values: {
          type: trade.type,
          category: categoryValues
            .flatMap((it) => it.options ?? [it])
            .find((it) => it.value === trade.categoryId),
          density: trade.density || '',
          quantityUnit: quantityUnitValues.find(
            (it) => it.value === trade.quantityUnit
          ),
          quantity: trade.quantity || '',
          quality: trade.quality,
          currency: currencyValues.find((it) => it.value === trade.currency),

          priceIndication:
            newPricingType === 'priceIndication' ? trade.priceIndication : '',

          priceBenchmarkIndex:
            newPricingType === 'priceBenchmark'
              ? benchmarkValues.find(
                  (it) => it.value === trade.priceBenchmark.priceIndex
                )
              : undefined,
          differentialType:
            !trade.priceBenchmark ||
            trade.priceBenchmark.differentialType ===
              Differential_Type.Percentage
              ? differentialValues[0]
              : differentialValues.find((it) => it.value === trade.currency),
          differentialValue:
            newPricingType === 'priceBenchmark'
              ? trade.priceBenchmark.differential
              : '',
          quotationPeriodStart:
            newPricingType === 'priceBenchmark'
              ? new Date(trade.priceBenchmark.quotationPeriodStart)
              : undefined,
          quotationPeriodEnd:
            newPricingType === 'priceBenchmark'
              ? new Date(trade.priceBenchmark.quotationPeriodEnd)
              : undefined,

          deliveryWindowStart: new Date(trade.deliveryWindowStart),
          deliveryWindowEnd: new Date(trade.deliveryWindowEnd),
          incoterm: incotermValues.find((it) => it.value === trade.incoTerm),
          deliveryPlace: trade.deliveryPlace,
          additionalInfo: trade.additionalInfo,

          contactInfoName: trade.contactInfo.name,
          contactInfoCompanyName: trade.contactInfo.companyName,
          contactInfoCompanyAddress: trade.contactInfo.companyAddress,
          contactInfoCountry: mapCountryToSelectValue(
            true,
            COUNTRY_MAP.get(trade.contactInfo.country) ?? COUNTRY_UNDEFINED
          ),
          contactInfoPhoneNumber: trade.contactInfo.phoneNumber,
          contactInfoEmail: trade.contactInfo.email,
        },
      });

      requestAnimationFrame(() => {
        updateFormikRef.current.setFieldValue(
          'contactInfoPhoneNumber',
          trade.contactInfo.phoneNumber
        );
        updateFormikRef.current.setFieldValue('density', trade.density);
      });
    } else {
      updateFormikRef.current.resetForm({ values: updateFormikInitialValues });
    }
  }, [editModal.data, updateFormikRef]);

  return (
    <>
      <Modal
        title="Edit Trade"
        contentClassName="TradeForm-modal"
        control={editModal}
        formProps={{
          onSubmit: updateFormik.handleSubmit,
        }}
        size="sm:max-w-3xl sm:w-auto"
        actions={
          <div className="p-4 lg:p-0 w-full bg-background-avg lg:bg-transparent flex justify-between">
            <Button
              className="w-1/3 lg:w-36"
              type="reset"
              onClick={editModal.close}
            >
              Cancel
            </Button>
            <Button
              className="w-2/3 lg:w-36"
              type="submit"
              name="submit"
              variant="primary"
            >
              Update Trade
            </Button>
          </div>
        }
      >
        <CommonFields
          formik={updateFormik}
          pricingType={pricingType}
          setPricingType={setPricingType}
          categoryValues={categoryValues}
        />

        <hr className="mt-6 bg-white opacity-10" />
        <p className="text-xl mb-0 font-emp font-light">Contact Information</p>

        <ContactInfoFields formik={updateFormik} />
      </Modal>
    </>
  );
};

export default UpdateModal;
