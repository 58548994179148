import * as React from 'react';

const currenciesThatWeHaveExchangeRatesFor = [
  'USD',
  'JPY',
  'BGN',
  'CZK',
  'DKK',
  'GBP',
  'HUF',
  'PLN',
  'RON',
  'SEK',
  'CHF',
  'ISK',
  'NOK',
  'HRK',
  'RUB',
  'TRY',
  'AUD',
  'BRL',
  'CAD',
  'CNY',
  'HKD',
  'IDR',
  'ILS',
  'INR',
  'KRW',
  'MXN',
  'MYR',
  'NZD',
  'PHP',
  'SGD',
  'THB',
  'ZAR',
  'EUR',
];

export const AVAILABLE_CURRENCIES = [
  { id: 'USD', name: 'US Dollar', symbol: '$' },
  { id: 'EUR', name: 'Euro', symbol: '€' },
  { id: 'CAD', name: 'Canadian Dollar', symbol: '$' },
  { id: 'AED', name: 'United Arab Emirates Dirham', symbol: 'د.إ.‏' },
  { id: 'AFN', name: 'Afghan Afghani', symbol: '؋' },
  { id: 'ALL', name: 'Albanian Lek', symbol: 'Lek' },
  { id: 'AMD', name: 'Armenian Dram', symbol: 'դր.' },
  { id: 'ARS', name: 'Argentine Peso', symbol: '$' },
  { id: 'AUD', name: 'Australian Dollar', symbol: '$' },
  { id: 'AZN', name: 'Azerbaijani Manat', symbol: 'ман.' },
  { id: 'BAM', name: 'Bosnia-Herzegovina Convertible Mark', symbol: 'KM' },
  { id: 'BDT', name: 'Bangladeshi Taka', symbol: '৳' },
  { id: 'BGN', name: 'Bulgarian Lev', symbol: 'лв.' },
  { id: 'BHD', name: 'Bahraini Dinar', symbol: 'د.ب.‏' },
  { id: 'BIF', name: 'Burundian Franc', symbol: 'FBu' },
  { id: 'BND', name: 'Brunei Dollar', symbol: '$' },
  { id: 'BOB', name: 'Bolivian Boliviano', symbol: 'Bs' },
  { id: 'BRL', name: 'Brazilian Real', symbol: 'R$' },
  { id: 'BWP', name: 'Botswanan Pula', symbol: 'P' },
  { id: 'BYN', name: 'Belarusian Ruble', symbol: 'руб.' },
  { id: 'BZD', name: 'Belize Dollar', symbol: '$' },
  { id: 'CDF', name: 'Congolese Franc', symbol: 'FrCD' },
  { id: 'CHF', name: 'Swiss Franc', symbol: 'CHF' },
  { id: 'CLP', name: 'Chilean Peso', symbol: '$' },
  { id: 'CNY', name: 'Chinese Yuan', symbol: 'CN¥' },
  { id: 'COP', name: 'Colombian Peso', symbol: '$' },
  { id: 'CRC', name: 'Costa Rican Colón', symbol: '₡' },
  { id: 'CVE', name: 'Cape Verdean Escudo', symbol: 'CV$' },
  { id: 'CZK', name: 'Czech Republic Koruna', symbol: 'Kč' },
  { id: 'DJF', name: 'Djiboutian Franc', symbol: 'Fdj' },
  { id: 'DKK', name: 'Danish Krone', symbol: 'kr' },
  { id: 'DOP', name: 'Dominican Peso', symbol: 'RD$' },
  { id: 'DZD', name: 'Algerian Dinar', symbol: 'د.ج.‏' },
  { id: 'EEK', name: 'Estonian Kroon', symbol: 'kr' },
  { id: 'EGP', name: 'Egyptian Pound', symbol: 'ج.م.‏' },
  { id: 'ERN', name: 'Eritrean Nakfa', symbol: 'Nfk' },
  { id: 'ETB', name: 'Ethiopian Birr', symbol: 'Br' },
  { id: 'GBP', name: 'British Pound Sterling', symbol: '£' },
  { id: 'GEL', name: 'Georgian Lari', symbol: 'GEL' },
  { id: 'GHS', name: 'Ghanaian Cedi', symbol: 'GH₵' },
  { id: 'GNF', name: 'Guinean Franc', symbol: 'FG' },
  { id: 'GTQ', name: 'Guatemalan Quetzal', symbol: 'Q' },
  { id: 'HKD', name: 'Hong Kong Dollar', symbol: '$' },
  { id: 'HNL', name: 'Honduran Lempira', symbol: 'L' },
  { id: 'HRK', name: 'Croatian Kuna', symbol: 'kn' },
  { id: 'HUF', name: 'Hungarian Forint', symbol: 'Ft' },
  { id: 'IDR', name: 'Indonesian Rupiah', symbol: 'Rp' },
  { id: 'ILS', name: 'Israeli New Sheqel', symbol: '₪' },
  { id: 'INR', name: 'Indian Rupee', symbol: 'টকা' },
  { id: 'IQD', name: 'Iraqi Dinar', symbol: 'د.ع.‏' },
  { id: 'IRR', name: 'Iranian Rial', symbol: '﷼' },
  { id: 'ISK', name: 'Icelandic Króna', symbol: 'kr' },
  { id: 'JMD', name: 'Jamaican Dollar', symbol: '$' },
  { id: 'JOD', name: 'Jordanian Dinar', symbol: 'د.أ.‏' },
  { id: 'JPY', name: 'Japanese Yen', symbol: '￥' },
  { id: 'KES', name: 'Kenyan Shilling', symbol: 'Ksh' },
  { id: 'KHR', name: 'Cambodian Riel', symbol: '៛' },
  { id: 'KMF', name: 'Comorian Franc', symbol: 'FC' },
  { id: 'KRW', name: 'South Korean Won', symbol: '₩' },
  { id: 'KWD', name: 'Kuwaiti Dinar', symbol: 'د.ك.‏' },
  { id: 'KZT', name: 'Kazakhstani Tenge', symbol: 'тңг.' },
  { id: 'LBP', name: 'Lebanese Pound', symbol: 'ل.ل.‏' },
  { id: 'LKR', name: 'Sri Lankan Rupee', symbol: 'SL Re' },
  { id: 'LTL', name: 'Lithuanian Litas', symbol: 'Lt' },
  { id: 'LVL', name: 'Latvian Lats', symbol: 'Ls' },
  { id: 'LYD', name: 'Libyan Dinar', symbol: 'د.ل.‏' },
  { id: 'MAD', name: 'Moroccan Dirham', symbol: 'د.م.‏' },
  { id: 'MDL', name: 'Moldovan Leu', symbol: 'MDL' },
  { id: 'MGA', name: 'Malagasy Ariary', symbol: 'MGA' },
  { id: 'MKD', name: 'Macedonian Denar', symbol: 'MKD' },
  { id: 'MMK', name: 'Myanma Kyat', symbol: 'K' },
  { id: 'MOP', name: 'Macanese Pataca', symbol: 'MOP$' },
  { id: 'MUR', name: 'Mauritian Rupee', symbol: 'MURs' },
  { id: 'MXN', name: 'Mexican Peso', symbol: '$' },
  { id: 'MYR', name: 'Malaysian Ringgit', symbol: 'RM' },
  { id: 'MZN', name: 'Mozambican Metical', symbol: 'MTn' },
  { id: 'NAD', name: 'Namibian Dollar', symbol: 'N$' },
  { id: 'NGN', name: 'Nigerian Naira', symbol: '₦' },
  { id: 'NIO', name: 'Nicaraguan Córdoba', symbol: 'C$' },
  { id: 'NOK', name: 'Norwegian Krone', symbol: 'kr' },
  { id: 'NPR', name: 'Nepalese Rupee', symbol: 'नेरू' },
  { id: 'NZD', name: 'New Zealand Dollar', symbol: '$' },
  { id: 'OMR', name: 'Omani Rial', symbol: 'ر.ع.‏' },
  { id: 'PAB', name: 'Panamanian Balboa', symbol: 'B/.' },
  { id: 'PEN', name: 'Peruvian Nuevo Sol', symbol: 'S/.' },
  { id: 'PHP', name: 'Philippine Peso', symbol: '₱' },
  { id: 'PKR', name: 'Pakistani Rupee', symbol: '₨' },
  { id: 'PLN', name: 'Polish Zloty', symbol: 'zł' },
  { id: 'PYG', name: 'Paraguayan Guarani', symbol: '₲' },
  { id: 'QAR', name: 'Qatari Rial', symbol: 'ر.ق.‏' },
  { id: 'RON', name: 'Romanian Leu', symbol: 'RON' },
  { id: 'RSD', name: 'Serbian Dinar', symbol: 'дин.' },
  { id: 'RUB', name: 'Russian Ruble', symbol: '₽.' },
  { id: 'RWF', name: 'Rwandan Franc', symbol: 'FR' },
  { id: 'SAR', name: 'Saudi Riyal', symbol: 'ر.س.‏' },
  { id: 'SDG', name: 'Sudanese Pound', symbol: 'SDG' },
  { id: 'SEK', name: 'Swedish Krona', symbol: 'kr' },
  { id: 'SGD', name: 'Singapore Dollar', symbol: '$' },
  { id: 'SOS', name: 'Somali Shilling', symbol: 'Ssh' },
  { id: 'SYP', name: 'Syrian Pound', symbol: 'ل.س.‏' },
  { id: 'THB', name: 'Thai Baht', symbol: '฿' },
  { id: 'TND', name: 'Tunisian Dinar', symbol: 'د.ت.‏' },
  { id: 'TOP', name: 'Tongan Paʻanga', symbol: 'T$' },
  { id: 'TRY', name: 'Turkish Lira', symbol: 'TL' },
  { id: 'TTD', name: 'Trinidad and Tobago Dollar', symbol: '$' },
  { id: 'TWD', name: 'New Taiwan Dollar', symbol: 'NT$' },
  { id: 'TZS', name: 'Tanzanian Shilling', symbol: 'TSh' },
  { id: 'UAH', name: 'Ukrainian Hryvnia', symbol: '₴' },
  { id: 'UGX', name: 'Ugandan Shilling', symbol: 'USh' },
  { id: 'UYU', name: 'Uruguayan Peso', symbol: '$' },
  { id: 'UZS', name: 'Uzbekistan Som', symbol: 'UZS' },
  { id: 'VEF', name: 'Venezuelan Bolívar', symbol: 'Bs.F.' },
  { id: 'VND', name: 'Vietnamese Dong', symbol: '₫' },
  { id: 'XAF', name: 'CFA Franc BEAC', symbol: 'FCFA' },
  { id: 'XOF', name: 'CFA Franc BCEAO', symbol: 'CFA' },
  { id: 'YER', name: 'Yemeni Rial', symbol: 'ر.ي.‏' },
  { id: 'ZAR', name: 'South African Rand', symbol: 'R' },
  { id: 'ZMK', name: 'Zambian Kwacha', symbol: 'ZK' },
  { id: 'ZWL', name: 'Zimbabwean Dollar', symbol: 'ZWL$' },
].filter((it) => currenciesThatWeHaveExchangeRatesFor.indexOf(it.id) > -1);

export const currencyValues = AVAILABLE_CURRENCIES.map((curr) => ({
  value: curr.id,
  label: (
    <div className="flex whitespace-nowrap">
      <span className="w-12">{curr.symbol}</span> | {curr.name}
    </div>
  ),
  buttonLabel: curr.symbol,
}));
